<template>
  <div class="row" :class="dashboard ? 'w-50' : ''">
    <b-col v-if="!dashboard" class="col-12 mt-5">
      <h3 class="ml-1">Seleccionar un cliente</h3>
    </b-col>
    <b-col class="col-md-4 col-sm-12" :class="dashboard ? 'col-lg-12' : ''">
      <v-select
        @input="onSelect"
        :options="elements"
        label="fullText"
        class="mx-1 v-select"
        :class="dashboard ? 'select-size-sm' : ''"
      ></v-select>
    </b-col>

    <div class="list-group-item" v-if="!isLoading && elements.length === 0">
      <div class="alert alert-info mb-0">No se encontraron resultados.</div>
    </div>
  </div>
</template>

<script>
import OperationService from "@/services/OperationService";

export default {
  name: "SelectCustomer",
  props: {
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      elements: [],
      meta: null,
    };
  },
  methods: {
    onLoadMore() {
      this.isLoading = true;
      OperationService.customers()
        .then(({ data }) => {
          this.isLoading = false;
          this.elements = data.data.map((d) => {
            return Object.assign(d, {
              fullText: d.brand,
              code: d.id,
              name: d.name,
              last_name: d.last_name,
              email: d.email,
            });
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelect(item) {
      this.isLoading = true;
      if (!item) {
        this.$emit("select", null);
      } else {
        OperationService.customer(item.code)
          .then(({ data }) => {
            this.isLoading = false;
            this.$emit("select", data.data);
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    this.onLoadMore();
  },
};
</script>
<style lang="scss" scoped>
.elements {
  height: 400px;
  overflow-y: auto;
}

.v-select {
  border-radius: 50px !important;
}
</style>
