<template>
  <b-card class="px-2 py-1">
    <div v-if="!client">
      <b-row>
        <b-col>
          <SelectCustomer @select="onSelect" />
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col sm="12" md="7" lg="7">
        <div class="collection">
          <OperationForm :client="client" @save="save($event)" v-if="client" />
        </div>
      </b-col>
      <b-col class="d-sm-none d-md-block" md="5" lg="5">
        <div v-if="client && client.collection === 'client'">
          <h3 class="title">DATOS BASICOS</h3>
          <RowComponent :title="'ID:'" :content="client.id.toString()" />
          <RowComponent :title="'EMAIL:'" :content="client.email" />
          <RowComponent :title="'CUIT/DNI:'" :content="client.dni" />
          <RowComponent :title="'NOMBRE:'" :content="client.name" />
          <RowComponent :title="'APELLIDO:'" :content="client.last_name" />
        </div>

        <div
          v-if="client && client.collection === 'client' && client.type === 'post-pago'"
        >
          <h2 class="title">DIRECCIÓN DE COLECTA</h2>
          <RowComponent :title="'PROVINCIA:'" :content="client.direction.province" />
          <RowComponent
            :title="'LOCALIDAD:'"
            :content="
              client.direction.location instanceof String
                ? client.direction.location
                : client.direction.location.location
            "
          />
          <RowComponent :title="'CALLE:'" :content="client.direction.street" />
          <RowComponent :title="'ALTURA:'" :content="client.direction.height" />
          <RowComponent
            :title="'CODIGO POSTAL:'"
            :content="client.direction.postal_code"
          />
        </div>

        <div
          class="collection d-none d-md-block"
          v-if="client && client.collection === 'deposit' && client.point"
        >
          <h3 class="text-nowrap font-weight-bold title">DEPOSITO</h3>
          <hr />
          <h3 class="text-wrap font-weight-bold title">
            DROPOFF:
            <span class="text-muted">Aquí debes entregar el paquete</span>
          </h3>

          <RowComponent
            :title="'PROVINCIA:'"
            :content="client.point.location.province.province"
          />
          <RowComponent :title="'LOCALIDAD:'" :content="client.point.location.location" />
          <RowComponent :title="'CALLE:'" :content="client.point.street" />
          <RowComponent :title="'ALTURA:'" :content="client.point.height" />
          <RowComponent :title="'CÓDIGO POSTAL:'" :content="client.point.postal_code" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import UserService from "@/services/UserService";

import SelectCustomer from "./components/SelectCustomer";
import OperationForm from "./OperationForm";

export default {
  name: "OperationAdd",
  components: {
    SelectCustomer,
    OperationForm,
  },
  data() {
    const client = UserService.isAdminOrStaff() ? null : UserService.user();
    return {
      client: client,
      isAdmin: UserService.isAdminOrStaff(),
    };
  },
  methods: {
    onSelect(item) {
      this.client = item;
    },
  },
};
</script>
